import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, SecurityContext } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { I18NextModule } from 'angular-i18next';
import 'hammerjs';
import { HighchartsChartModule } from 'highcharts-angular';

import { AuthModule } from '@core/auth/auth.module';
import { SentryErrorHandler } from '@core/error-handlers/sentry-error-handler';
import { I18N_PROVIDERS } from '@core/i18next-translate/translation';
import { ErrorHandlingInterceptor } from '@core/interceptor/error-handling.interceptor';
import { HttpConfigInterceptor } from '@core/interceptor/intercept-response';
import { TimeoutInterceptor } from '@core/interceptor/timeout.interceptor';
import { AuthService } from '@core/services/auth.service';
import { BaseBulkUploadService } from '@core/services/bulk-upload.service';
import { ConfigService } from '@core/services/config.service';
import { DateTimeService } from '@core/services/date-time.service';
import { FacadeService } from '@core/services/facade.service';
import { FileDownloadService } from '@core/services/file-download.service';
import { MatPaginatorI18nService } from '@core/services/mat-paginator-i18n.service';
import { DimensionsService } from '@core/services/report/dimensions.service';
import { MetricsService } from '@core/services/report/metrics.service';
import { MetricsTranslateService } from '@core/services/report/metricsTranslate.service';
import { ReportTypeService } from '@core/services/report/report-type.service';
import { SplashScreenService } from '@core/services/splash-screen.service';
import { StorageService } from '@core/services/storage.service';
import { ToasterService } from '@core/services/toaster.service';
import { MainModule } from '@main/main.module';
import { Error404Component } from '@main/pages/errors/404/error-404.component';
import { Error404Module } from '@main/pages/errors/404/error-404.module';
import { ReportService } from '@main/report/report.service';
import { MaterialModule } from '@shared/material.module';
import { NgxDaterangepickerMd } from '@shared/ngx-date-range-picker-material/src';
import { SharedModule } from '@shared/shared.module';
import { SidenavService } from '@shared/sidenav/sidenav.service';
import { ToolbarService } from '@shared/toolbar/toolbar.service';
import { DragulaModule } from 'ng2-dragula';
import { ToastrModule } from 'ngx-toastr';
import { register } from 'swiper/element';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { DateTimeFnsService } from './core/services/date-time-fns.service';
import { ThemesService } from './core/services/themes.service';
import { RegularMaintainNotifyComponent } from './main/pages/maintenance-banner/regular-maintain-notify/regular-maintain-notify.component';
import { NgxDaterangepickerFns } from './shared/date-range-picker-material-fns/src/daterangepicker.module';
import { MarkdownModule } from 'ngx-markdown';
import { provideNamedApollo } from 'apollo-angular';
import { apolloSettings } from './graphql.module';

register();

const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: '',
    redirectTo: 'pages/auth/login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: Error404Component,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    SharedModule,
    AuthModule,
    MainModule,
    MaterialModule,
    Error404Module,
    HighchartsChartModule,
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
    I18NextModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    NgxDaterangepickerFns.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    DragulaModule.forRoot(),
    RegularMaintainNotifyComponent,
    MarkdownModule.forRoot({ sanitize: SecurityContext.NONE }),
  ],
  providers: [
    provideNamedApollo(apolloSettings),
    ThemesService,
    StorageService,
    FileDownloadService,
    SplashScreenService,
    ConfigService,
    AuthService,
    ReportService,
    ToasterService,
    BaseBulkUploadService,
    AppService,
    DateTimeService,
    DateTimeFnsService,
    DimensionsService,
    MetricsService,
    ReportTypeService,
    MetricsTranslateService,
    FacadeService,
    SidenavService,
    ToolbarService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18nService },
    I18N_PROVIDERS,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
