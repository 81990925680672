export interface InterceptorURLConfig {
  urlPattern: RegExp;
  headers: { [key: string]: any };
  overwriteHeaders?: boolean;
}

export interface ExternalUrlConfig {
  url: string;
  path: string; // Path could be empty if no need to check
}

// NOTE: order is important interceptor will pick headers of first match regex
export const URLsConfig: InterceptorURLConfig[] = [
  {
    urlPattern: /.*storage\.googleapis\.com.*/,
    headers: {},
    overwriteHeaders: true,
  },
  {
    urlPattern: /.*\/bulk_.*/,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/uac_bulk_.*/,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/pwa\/admin.*/,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/manifest.*/,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/pwa\/overview\/\d+\/push_notification\//,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/pwa\/overview\/\d+\/push_notification\/(\d+\/)?/,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/pwa\/overview\/\d+\/a2hs\/\d+\/update_card_icon\//,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/pwa\/overview\/\d+\/notification_prompt\/\d+\/update_card_icon\//,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/amp\/header\/\d+\//,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/amp\/general\/\d+\//,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/web_stories\/general\/\d+\//,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/web_stories\/url\/\d+\//,
    headers: {
      Accept: 'application/json',
    },
  },
  // /game_tag/game_setting/
  {
    urlPattern: /.*\/game_tag\/game_setting\//,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/game_tag\/game_setting\/\d+\//,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /video_generation/,
    headers: {},
  },
  {
    urlPattern: /graphql\/v2/,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*\/ats\/site_setting\/\d+\//,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /ats_prebid_bidder_setting_bulk_upload/,
    headers: {
      Accept: 'application/json',
    },
  },
  {
    urlPattern: /.*/,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
];

export const ExternalURLsConfig: ExternalUrlConfig[] = [
  {
    url: 'video.anymanager.io/video',
    path: 'short_video',
  },
  {
    url: 'video.anymanager.io/video',
    path: 'video_generation',
  },
  {
    url: 'video.anymanager.io/video',
    path: 'mp4_video_content',
  },
];
