<div
  #pickerContainer
  [ngClass]="{
    ltr: locale.direction === 'ltr',
    rtl: locale.direction === 'rtl',
    shown: isShown || inline,
    hidden: !isShown && !inline,
    inline: inline,
    double: !singleDatePicker && showCalInRanges,
    'show-ranges': rangesArray.length,
    'single-date-picker': singleDatePicker,
  }"
  [class]="'drops-' + drops + '-' + opens + ' ' + classCustom"
  [class.!tw-overflow-visible]="timePicker"
  [class.single-time-picker]="singleDatePicker"
  class="md-drppicker-fns"
>
  <div class="container">
    @if (rangesArray.length > 0) {
      <div class="ranges">
        <ul>
          @for (range of rangesArray; track range) {
            <li>
              <button
                [disabled]="disableRange(range)"
                [ngClass]="{ active: range === chosenRange }"
                (click)="clickRange($event, range)"
                class="range-btn select-range-button"
                anyButton
                themeColor="primary"
              >
                {{ 'DATE_RANGE.' + range.split(' ').join('_').toUpperCase() | i18next: { defaultValue: '' } }}
              </button>
            </li>
          }
        </ul>
      </div>
    }

    <section class="tw-flex tw-flex-col">
      <section class="tw-flex tw-gap-16px tw-px-[16px]">
        @if (showCalInRanges) {
          <div [ngClass]="{ right: singleDatePicker, left: !singleDatePicker }" class="calendar">
            <div class="calendar-table">
              @if (calendarVariables) {
                <div class="calendar-selection left">
                  @if (!calendarVariables.left.minDate || !linkedCalendars || true) {
                    <div [class.disabled-state]="disableLeftCalendarArrow(sideEnum.left)" (click)="clickPrev(sideEnum.left)" class="prev">
                      <mat-icon>chevron_left</mat-icon>
                    </div>
                  }
                  <div class="drp-animate tw-inline-flex tw-h-[32px] tw-w-[194px]">
                    @if (showDropdowns && calendarVariables.left.dropdowns) {
                      <div class="tw-relative tw-h-full tw-w-[117px]">
                        @if (showDropdowns) {
                          <div [formGroup]="monthYearSelectForm" class="month-filter tw-absolute tw-w-[117px]">
                            <app-select
                              *appSelectItems="let options of leftMonthOptionsSignal(); bindSearch: ''; bindValue: 'value'"
                              [formControl]="monthYearSelectForm.controls.left.controls.month"
                              [disabledItems]="calendarVariables.left.dropdowns.disabledMonthOptions"
                              [clearable]="false"
                              (selectChange)="monthChanged($event, sideEnum.left)"
                              class="tw-w-full"
                              hideTotalCount
                            >
                              <ng-container *appSelectLabel="let item of options">
                                <span class="month-year-select-text">{{ item.label }}</span>
                              </ng-container>

                              <ng-container *appSelectOption="let item of options">
                                <div class="month-year-select-text">{{ item.label }}</div>
                              </ng-container>
                            </app-select>
                          </div>
                        }
                      </div>
                      <div class="tw-relative tw-h-full tw-w-[77px]">
                        @if (showDropdowns) {
                          <div [formGroup]="monthYearSelectForm" class="year-filter tw-absolute tw-w-[77px]">
                            <app-select
                              *appSelectItems="let options of leftYearOptionsSignal(); bindSearch: ''; bindValue: 'value'"
                              [formControl]="monthYearSelectForm.controls.left.controls.year"
                              [clearable]="false"
                              (selectChange)="yearChanged($event, sideEnum.left)"
                              class="tw-w-full"
                              hideTotalCount
                            >
                              <ng-container *appSelectLabel="let item of options">
                                <span class="month-year-select-text">{{ item.label }}</span>
                              </ng-container>

                              <ng-container *appSelectOption="let item of options">
                                <div class="month-year-select-text">{{ item.value }}</div>
                              </ng-container>
                            </app-select>
                          </div>
                        }
                      </div>
                    }
                    @if (!showDropdowns || !calendarVariables.left.dropdowns) {
                      {{ locale.monthNames[fns.getMonth(calendarVariables?.left?.calendar[1][1])] }}
                      {{ fns.format(calendarVariables?.left?.calendar[1][1], ' yyyy') }}
                    }
                  </div>
                  @if (
                    (!calendarVariables.left.maxDate ||
                      fns.isAfter(calendarVariables.left.maxDate, calendarVariables.left.calendar.lastDay)) &&
                    (!linkedCalendars || singleDatePicker)
                  ) {
                    <div (click)="clickNext(sideEnum.left)" class="next">
                      <mat-icon>chevron_right</mat-icon>
                    </div>
                  }
                </div>
                <table class="table-condensed">
                  <thead>
                    <tr class="week-days">
                      @if (showWeekNumbers || showISOWeekNumbers) {
                        <th class="week">
                          <span>{{ locale.weekLabel }}</span>
                        </th>
                      }
                      @for (dayofweek of locale.daysOfWeek; track dayofweek) {
                        <th>
                          <span>{{ dayofweek }}</span>
                        </th>
                      }
                    </tr>
                  </thead>
                  <tbody #leftCalendar class="drp-animate">
                    @for (row of calendarVariables.left.calRows; track row) {
                      <tr [class]="calendarVariables.left.classes[row].classList">
                        <!-- add week number -->
                        @if (showWeekNumbers) {
                          <td class="week">
                            <span>{{ fns.getWeek(calendarVariables.left.calendar[row][0]) }}</span>
                          </td>
                        }
                        @if (showISOWeekNumbers) {
                          <td class="week">
                            <span>{{ fns.getISOWeek(calendarVariables.left.calendar[row][0]) }}</span>
                          </td>
                        }
                        <!-- cal -->
                        @for (col of calendarVariables.left.calCols; track col) {
                          <td
                            [class]="calendarVariables.left.classes[row][col]"
                            [class.single-date-selected]="isSingleDateSelected(sideEnum.left, row, col)"
                            (click)="clickDate($event, sideEnum.left, row, col)"
                          >
                            <span>{{ fns.getDate(calendarVariables.left.calendar[row][col]) }}</span>
                          </td>
                        }
                      </tr>
                    }
                  </tbody>
                </table>
              }
            </div>
            @if (timePicker) {
              <div [formGroup]="timeSelectForm" class="calendar-time">
                @let leftHourOptions = leftHourOptionsSignal();
                @if (leftHourOptions && leftHourOptions.length > 0) {
                  <app-select
                    *appSelectItems="let options of leftHourOptions; bindSearch: ''; bindValue: 'value'"
                    [formControl]="timeSelectForm.controls.left.controls.hour"
                    [clearable]="false"
                    [disabledItems]="timepickerVariables.left.disabledHours"
                    [disabled]="!endDate"
                    (selectChange)="timeChanged($event, sideEnum.left, 'selectedHour')"
                    class="tw-w-full"
                    hideTotalCount
                  >
                    <ng-container *appSelectLabel="let item of options">
                      <span class="month-year-select-text">{{ item.label }}</span>
                    </ng-container>

                    <ng-container *appSelectOption="let item of options">
                      <div class="month-year-select-text">{{ item.label }}</div>
                    </ng-container>
                  </app-select>
                }
                @let leftMinuteOptions = leftMinuteOptionsSignal();
                @if (leftMinuteOptions && leftMinuteOptions.length > 0) {
                  <app-select
                    *appSelectItems="let options of leftMinuteOptions; bindSearch: ''; bindValue: 'value'"
                    [formControl]="timeSelectForm.controls.left.controls.minute"
                    [clearable]="false"
                    [disabledItems]="timepickerVariables.left.disabledMinutes"
                    [disabled]="!endDate"
                    (selectChange)="timeChanged($event, sideEnum.left, 'selectedMinute')"
                    class="tw-w-full"
                    hideTotalCount
                  >
                    <ng-container *appSelectLabel="let item of options">
                      <span class="month-year-select-text">{{ item.label }}</span>
                    </ng-container>

                    <ng-container *appSelectOption="let item of options">
                      <div class="month-year-select-text">{{ item.label }}</div>
                    </ng-container>
                  </app-select>
                }
              </div>
            }
          </div>
        }
        @if (showCalInRanges && !singleDatePicker) {
          <div class="calendar left">
            <div class="calendar-table">
              @if (calendarVariables) {
                <div class="calendar-selection right">
                  @if (
                    (!calendarVariables.right.minDate ||
                      isBefore(calendarVariables.right.minDate, calendarVariables.right.calendar.firstDay)) &&
                    !linkedCalendars
                  ) {
                    <div [class.disabled-state]="disableLeftCalendarArrow(sideEnum.right)" (click)="clickPrev(sideEnum.right)" class="prev">
                      <mat-icon>chevron_left</mat-icon>
                    </div>
                  }
                  <div class="drp-animate tw-inline-flex tw-h-[32px] tw-w-[194px]">
                    @if (showDropdowns && calendarVariables.right.dropdowns) {
                      <div class="tw-relative tw-h-full tw-w-[117px]">
                        @if (showDropdowns) {
                          <div [formGroup]="monthYearSelectForm" class="month-filter tw-absolute tw-w-[117px]">
                            <app-select
                              *appSelectItems="let options of rightMonthOptionsSignal(); bindSearch: ''; bindValue: 'value'"
                              [formControl]="monthYearSelectForm.controls.right.controls.month"
                              [disabledItems]="calendarVariables.right.dropdowns.disabledMonthOptions"
                              [clearable]="false"
                              (selectChange)="monthChanged($event, sideEnum.right)"
                              class="tw-w-full"
                              hideTotalCount
                            >
                              <ng-container *appSelectLabel="let item of options">
                                <span class="month-year-select-text">{{ item.label }}</span>
                              </ng-container>

                              <ng-container *appSelectOption="let item of options">
                                <div class="month-year-select-text">{{ item.label }}</div>
                              </ng-container>
                            </app-select>
                          </div>
                        }
                      </div>
                      <div class="tw-relative tw-h-full tw-w-[77px]">
                        @if (showDropdowns) {
                          <div class="year-filter tw-absolute tw-w-[77px]">
                            <app-select
                              *appSelectItems="let options of rightYearOptionsSignal(); bindSearch: ''; bindValue: 'value'"
                              [formControl]="monthYearSelectForm.controls.right.controls.year"
                              [clearable]="false"
                              (selectChange)="yearChanged($event, sideEnum.right)"
                              class="tw-w-full"
                              hideTotalCount
                            >
                              <ng-container *appSelectLabel="let item of options">
                                <span class="month-year-select-text">{{ item.label }}</span>
                              </ng-container>

                              <ng-container *appSelectOption="let item of options">
                                <div class="month-year-select-text">{{ item.value }}</div>
                              </ng-container>
                            </app-select>
                          </div>
                        }
                      </div>
                    }
                    @if (!showDropdowns || !calendarVariables.right.dropdowns) {
                      {{ locale.monthNames[fns.getMonth(calendarVariables?.right?.calendar[1][1])] }}
                      {{ fns.format(calendarVariables?.right?.calendar[1][1], ' yyyy') }}
                    }
                  </div>
                  @if (
                    !calendarVariables.right.maxDate ||
                    (fns.isAfter(calendarVariables.right.maxDate, calendarVariables.right.calendar.lastDay) &&
                      (!linkedCalendars || singleDatePicker || true))
                  ) {
                    <div (click)="clickNext(sideEnum.right)" class="next">
                      <mat-icon>chevron_right</mat-icon>
                    </div>
                  }
                </div>
                <table class="table-condensed">
                  <thead>
                    <tr class="week-days">
                      @if (showWeekNumbers || showISOWeekNumbers) {
                        <th class="week">
                          <span>{{ locale.weekLabel }}</span>
                        </th>
                      }
                      @for (dayofweek of locale.daysOfWeek; track dayofweek) {
                        <th>
                          <span>{{ dayofweek }}</span>
                        </th>
                      }
                    </tr>
                  </thead>
                  <tbody #rightCalendar class="drp-animate">
                    @for (row of calendarVariables.right.calRows; track row) {
                      <tr [class]="calendarVariables.right.classes[row].classList">
                        @if (showWeekNumbers) {
                          <td class="week">
                            <span>{{ fns.getWeek(calendarVariables.right.calendar[row][0]) }}</span>
                          </td>
                        }
                        @if (showISOWeekNumbers) {
                          <td class="week">
                            <span>{{ fns.getISOWeek(calendarVariables.right.calendar[row][0]) }}</span>
                          </td>
                        }
                        @for (col of calendarVariables.right.calCols; track col) {
                          <td
                            [class]="calendarVariables.right.classes[row][col]"
                            [class.single-date-selected]="isSingleDateSelected(sideEnum.right, row, col)"
                            (click)="clickDate($event, sideEnum.right, row, col)"
                          >
                            <span>{{ fns.getDate(calendarVariables.right.calendar[row][col]) }}</span>
                          </td>
                        }
                      </tr>
                    }
                  </tbody>
                </table>
              }
            </div>
            @if (timePicker) {
              <div [formGroup]="timeSelectForm" class="calendar-time">
                @let rightHourOptions = rightHourOptionsSignal();
                @if (rightHourOptions && rightHourOptions.length > 0) {
                  <app-select
                    *appSelectItems="let options of rightHourOptionsSignal(); bindSearch: ''; bindValue: 'value'"
                    [formControl]="timeSelectForm.controls.right.controls.hour"
                    [clearable]="false"
                    [disabledItems]="timepickerVariables.right.disabledHours"
                    [disabled]="!endDate"
                    (selectChange)="timeChanged($event, sideEnum.right, 'selectedHour')"
                    class="tw-w-full"
                    hideTotalCount
                  >
                    <ng-container *appSelectLabel="let item of options">
                      <span class="month-year-select-text">{{ item.label }}</span>
                    </ng-container>

                    <ng-container *appSelectOption="let item of options">
                      <div class="month-year-select-text">{{ item.label }}</div>
                    </ng-container>
                  </app-select>
                }
                @let rightMinuteOptions = rightMinuteOptionsSignal();
                @if (rightMinuteOptions && rightMinuteOptions.length > 0) {
                  <app-select
                    *appSelectItems="let options of rightMinuteOptionsSignal(); bindSearch: ''; bindValue: 'value'"
                    [formControl]="timeSelectForm.controls.right.controls.minute"
                    [clearable]="false"
                    [disabledItems]="timepickerVariables.right.disabledMinutes"
                    [disabled]="!endDate"
                    (selectChange)="timeChanged($event, sideEnum.right, 'selectedMinute')"
                    class="tw-w-full"
                    hideTotalCount
                  >
                    <ng-container *appSelectLabel="let item of options">
                      <span class="month-year-select-text">{{ item.label }}</span>
                    </ng-container>

                    <ng-container *appSelectOption="let item of options">
                      <div class="month-year-select-text">{{ item.label }}</div>
                    </ng-container>
                  </app-select>
                }
              </div>
            }
          </div>
        }
      </section>
      @if (!autoApply && (!rangesArray.length || (showCalInRanges && !singleDatePicker))) {
        <div class="action-container">
          <div class="date-range ranges-placeholder">
            {{ dateRangeTextPreview }}
          </div>
          <div class="buttons">
            @if (showClearButton) {
              <button [outlined]="true" [themeColor]="'default'" (click)="clear()" class="mx-2" anyButton title="clear the date">
                {{ locale.clearLabel }}
              </button>
            }

            @if (showCancel) {
              <button [outlined]="true" (click)="clickCancel($event)" class="cancel-btn" anyButton themeColor="default">
                {{ locale.cancelLabel }}
              </button>
            }
            <button [disabled]="applyBtn.disabled" (click)="clickApply($event)" anyButton themeColor="primary">
              {{ locale.applyLabel }}
            </button>
          </div>
        </div>
      }
    </section>
  </div>
</div>
