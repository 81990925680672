import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { AuthService } from '@core/services/auth.service';
import { FileDownloadService } from '@core/services/file-download.service';
import { StorageService } from '@core/services/storage.service';
import { AdUnit } from '@main/ad-unit/ad-unit.model';
import {
  ATSBidderResponse,
  ApmAdSetting,
  AtsBuildResponse,
  AtsRestoreResponse,
  AtsVersion,
  AtsVersionResponse,
  BidderAdUnit,
  BidderSite,
  BulkUpdateChangePathRegex,
  CommonAd,
  CompareVersion,
  CustomScriptAdSetting,
  CustomScriptTemplate,
  ExecuteOrderBody,
  GAMNetworkResponse,
  PageGroup,
  PathSettingResponse,
  PathSettingType,
  PrebidBidderSettingParams,
  SiteSetting,
  UserIdModuleConfigResponse,
} from '@main/ats/ats.model';
import { GqlParams } from '@src/app/core/utils/type-helper';
import { Apollo } from 'apollo-angular';
import queryString from 'query-string';
import { map, Observable } from 'rxjs';
import { PREVALIDATE_ADAGIO_SITE_NAME_DOMAIN_QUERY, VIDEO_SETTING_LIST_QUERY, YOUTUBE_VIDEOS_QUERY } from './ats.graphql';

@Injectable()
export class AtsService {
  readonly gqlV2 = 'v2';
  constructor(
    private http: HttpClient,
    private fileDownloadService: FileDownloadService,
    private authService: AuthService,
    private storageService: StorageService,
    private readonly apollo: Apollo
  ) {}

  public listPathSettings(id: number, params: any): Observable<PathSettingResponse> {
    return this.http.get<PathSettingResponse>(AppService.getAppUri(`/ats/site_setting/${id}/path_setting/`), { params });
  }

  public savePathSettings(id: number, data: ExecuteOrderBody): Observable<ExecuteOrderBody> {
    return this.http.post<ExecuteOrderBody>(AppService.getAppUri(`/ats/site_setting/${id}/execute_order/`), data);
  }

  public patchPathSettings(id: number, data: Partial<ExecuteOrderBody>): Observable<Partial<ExecuteOrderBody>> {
    return this.http.post<Partial<ExecuteOrderBody>>(AppService.getAppUri(`/ats/site_setting/${id}/execute_order/`), data);
  }

  public updateSiteSettings(id: number, data: FormData): Observable<SiteSetting> {
    return this.http.patch<SiteSetting>(AppService.getAppUri(`/ats/site_setting/${id}/`), data);
  }

  public versions(id: number, params: any): Observable<AtsVersionResponse> {
    return this.http.get<AtsVersionResponse>(AppService.getAppUri(`/ats/site_setting/${id}/ats_version/?${queryString.stringify(params)}`));
  }

  public buildAtsVersion(id: number, data: { name: string; ats_js_version: number }): Observable<AtsBuildResponse> {
    return this.http.post<AtsBuildResponse>(AppService.getAppUri(`/ats/site_setting/${id}/ats_version/build/`), data);
  }

  public restoreAtsVersion(id: number, versionId: number): Observable<AtsRestoreResponse> {
    return this.http.get<AtsRestoreResponse>(AppService.getAppUri(`/ats/site_setting/${id}/ats_version/${versionId}/restore/`));
  }

  public getAdUnit(siteId: number, id: number): Observable<AdUnit> {
    return this.http.get<AdUnit>(AppService.getAppUri(`/ats/site_setting/${siteId}/ad_unit/${id}/`));
  }

  public getSiteSettings(id: number, params?: any): Observable<SiteSetting> {
    return this.http.get<SiteSetting>(AppService.getAppUri(`/ats/site_setting/${id}/`), { params });
  }

  getAdTypeURI(adType: PathSettingType): string {
    let adTypeURI: string = '';
    switch (adType) {
      case PathSettingType.InterstitialAd:
        adTypeURI = 'interstitial_setting';
        break;
      case PathSettingType.SkyscraperAd:
        adTypeURI = 'skyscraper_setting';
        break;
      case PathSettingType.OverlayAd:
        adTypeURI = 'overlay_setting';
        break;
      case PathSettingType.InsertAd:
        adTypeURI = 'insert_ad_setting';
        break;
      case PathSettingType.PrebidOnlyAd:
        adTypeURI = 'prebid_only_setting';
        break;
      case PathSettingType.CustomScript:
        adTypeURI = 'custom_script_setting';
        break;
      case PathSettingType.AdxInterstitial:
        adTypeURI = 'adx_interstitial_setting';
        break;
      case PathSettingType.APM:
        adTypeURI = 'apm_ad_setting';
        break;
      case PathSettingType.Parallax:
        adTypeURI = 'parallax_setting';
        break;
      case PathSettingType.ReplaceAd:
        adTypeURI = 'replace_ad_setting';
        break;
      case PathSettingType.AdxOverlay:
        adTypeURI = 'adx_overlay_setting';
        break;
      case PathSettingType.SliderAd:
        adTypeURI = 'slider_ad_setting';
        break;
      case PathSettingType.Instream:
        adTypeURI = 'video_player_setting';
        break;
      case PathSettingType.AdXReward:
        adTypeURI = 'adx_reward';
        break;
      default:
        throw new Error('Invalid Ad type');
    }
    return adTypeURI;
  }

  public getSiteAdTypeSetting(siteID: number, settingID: number, adType: PathSettingType, params?: any): Observable<CommonAd> {
    const URI: string = `/ats/site_setting/${siteID}/${this.getAdTypeURI(adType)}/${settingID}/`;
    return this.http.get<CommonAd>(AppService.getAppUri(URI), { params });
  }

  public updateSiteAdTypeSetting(
    siteID: number,
    settingID: number,
    adType: PathSettingType,
    data: Partial<CommonAd>
  ): Observable<CommonAd> {
    const URI: string = `/ats/site_setting/${siteID}/${this.getAdTypeURI(adType)}/${settingID}/`;
    return this.http.patch<CommonAd>(AppService.getAppUri(URI), data);
  }

  public addSiteAdTypeSetting(siteID: number, adType: PathSettingType, data: CommonAd): Observable<CommonAd> {
    const URI: string = `/ats/site_setting/${siteID}/${this.getAdTypeURI(adType)}/`;
    return this.http.post<CommonAd>(AppService.getAppUri(URI), data);
  }

  public getBidders(_params?: any): Observable<ATSBidderResponse> {
    const params = {
      ..._params,
      is_bidder_status_active: true,
    };
    return this.http.get<ATSBidderResponse>(AppService.getAppUri('/ats/prebid_bidder/'), { params });
  }

  public getRedirectURL(adType: PathSettingType): string {
    let adTypeURI: string = '';
    switch (adType) {
      case PathSettingType.InterstitialAd:
        adTypeURI = '/apps/ats/interstitial-settings';
        break;
      case PathSettingType.SkyscraperAd:
        adTypeURI = '/apps/ats/skyscraper-settings';
        break;
      case PathSettingType.OverlayAd:
        adTypeURI = '/apps/ats/overlay-settings';
        break;
      case PathSettingType.InsertAd:
        adTypeURI = '/apps/ats/insert-settings';
        break;
      case PathSettingType.PrebidOnlyAd:
        adTypeURI = '/apps/ats/prebid-settings';
        break;
      case PathSettingType.CustomScript:
        adTypeURI = '/apps/ats/custom-script-settings';
        break;
      case PathSettingType.AdxInterstitial:
        adTypeURI = '/apps/ats/adx-interstitial-settings';
        break;
      case PathSettingType.APM:
        adTypeURI = '/apps/ats/apm-settings';
        break;
      case PathSettingType.Parallax:
        adTypeURI = '/apps/ats/parallax-settings';
        break;
      case PathSettingType.ReplaceAd:
        adTypeURI = '/apps/ats/replace-ad';
        break;
      case PathSettingType.AdxOverlay:
        adTypeURI = '/apps/ats/adx-overlay';
        break;
      case PathSettingType.SliderAd:
        adTypeURI = '/apps/ats/slider';
        break;
      case PathSettingType.Instream:
        adTypeURI = '/apps/ats/instream';
        break;
      case PathSettingType.AdXReward:
        adTypeURI = '/apps/ats/adx-reward';
        break;
      default:
        adTypeURI = '/apps/ats/site-top';
        break;
    }
    return adTypeURI;
  }

  getCustomScriptTemplates(params?: any): Observable<CustomScriptTemplate> {
    return this.http.get<CustomScriptTemplate>(AppService.getAppUri('/ats/custom_script_template/'), { params });
  }

  public getSiteCustomScriptSetting(siteID: number, settingID: number, params?: any): Observable<CustomScriptAdSetting> {
    const URI: string = `/ats/site_setting/${siteID}/${this.getAdTypeURI(PathSettingType.CustomScript)}/${settingID}/`;
    return this.http.get<CustomScriptAdSetting>(AppService.getAppUri(URI), { params });
  }

  public addCustomScriptSetting(siteID: number, data: CustomScriptAdSetting): Observable<CustomScriptAdSetting> {
    const URI: string = `/ats/site_setting/${siteID}/${this.getAdTypeURI(PathSettingType.CustomScript)}/`;
    return this.http.post<CustomScriptAdSetting>(AppService.getAppUri(URI), data);
  }

  public updateCustomScriptSetting(
    siteID: number,
    settingID: number,
    data: Partial<CustomScriptAdSetting>
  ): Observable<CustomScriptAdSetting> {
    const URI: string = `/ats/site_setting/${siteID}/${this.getAdTypeURI(PathSettingType.CustomScript)}/${settingID}/`;
    return this.http.patch<CustomScriptAdSetting>(AppService.getAppUri(URI), data);
  }

  public addCustomScriptTemplate(data: Partial<CustomScriptTemplate>): Observable<CustomScriptTemplate> {
    return this.http.post<CustomScriptTemplate>(AppService.getAppUri('/ats/custom_script_template/'), data);
  }

  public getGAMNetworks(siteID: number, params?: any): Observable<GAMNetworkResponse> {
    return this.http.get<GAMNetworkResponse>(AppService.getAppUri(`/ats/site_setting/${siteID}/gam_network/`), { params });
  }

  public getApmAdSetting(siteID: number, settingID: number, params?: any): Observable<ApmAdSetting> {
    const URI: string = `/ats/site_setting/${siteID}/${this.getAdTypeURI(PathSettingType.APM)}/${settingID}/`;
    return this.http.get<ApmAdSetting>(AppService.getAppUri(URI), { params });
  }

  public addApmAdSetting(siteID: number, data: ApmAdSetting): Observable<ApmAdSetting> {
    const URI: string = `/ats/site_setting/${siteID}/${this.getAdTypeURI(PathSettingType.APM)}/`;
    return this.http.post<ApmAdSetting>(AppService.getAppUri(URI), data);
  }

  public updateApmAdSetting(siteID: number, settingID: number, data: Partial<ApmAdSetting>): Observable<ApmAdSetting> {
    const URI: string = `/ats/site_setting/${siteID}/${this.getAdTypeURI(PathSettingType.APM)}/${settingID}/`;
    return this.http.patch<ApmAdSetting>(AppService.getAppUri(URI), data);
  }

  public addBidderAdUnit(data: BidderAdUnit): Observable<BidderAdUnit> {
    const URI: string = `/ats/bidder_ad_unit/`;
    return this.http.post<BidderAdUnit>(AppService.getAppUri(URI), data);
  }

  public updateBidderAdUnit(data: BidderAdUnit, id: number): Observable<BidderAdUnit> {
    const URI: string = `/ats/bidder_ad_unit/${id}/`;
    return this.http.patch<BidderAdUnit>(AppService.getAppUri(URI), data);
  }

  public addBidderSite(data: BidderSite): Observable<BidderSite> {
    const URI: string = `/ats/bidder_site/`;
    return this.http.post<BidderSite>(AppService.getAppUri(URI), data);
  }

  public updateBidderSite(data: BidderSite, id: number): Observable<BidderSite> {
    const URI: string = `/ats/bidder_site/${id}/`;
    return this.http.patch<BidderSite>(AppService.getAppUri(URI), data);
  }

  public versionCompareData(siteID: number, sourceVersionID: number, targetVersionID: number = null): Observable<CompareVersion> {
    const baseURL = `/ats/site_setting/${siteID}/ats_version/${sourceVersionID}/compare/`;
    const URI: string = targetVersionID ? baseURL + targetVersionID + '/' : baseURL;
    return this.http.get<CompareVersion>(AppService.getAppUri(URI));
  }

  async downloadVersion(params) {
    const formatted_params = {};
    const headers = {
      Authorization: `JWT ${this.authService.adpp_jwt_token}`,
      'Accept-Language': this.storageService.storage.getItem('lang'),
    };
    Object.keys(params).forEach((key) => {
      if (key.indexOf('_ids')) {
        formatted_params[key] = formatted_params[key] === 'all' ? '' : params[key];
      } else {
        formatted_params[key] = params[key];
      }
    });
    const url = AppService.getAppUri(`/ats/download_site_setting/?${queryString.stringify(formatted_params)}`);
    return this.fileDownloadService.asyncDownload(url, headers);
  }

  public getBidderSite(id: number): Observable<BidderSite> {
    const URI: string = `/ats/bidder_site/${id}/`;
    return this.http.get<BidderSite>(AppService.getAppUri(URI));
  }

  public getPageGroupDetails(id: number): Observable<PageGroup> {
    const URL: string = `/ats/smart_adserver_page_group/${id}/`;
    return this.http.get<PageGroup>(AppService.getAppUri(URL));
  }

  public createPageGroup(data: PageGroup): Observable<PageGroup> {
    const URL: string = `/ats/smart_adserver_page_group/`;
    return this.http.post<PageGroup>(AppService.getAppUri(URL), data);
  }

  public updatePageGroup(id: number, data: PageGroup): Observable<PageGroup> {
    const URL: string = `/ats/smart_adserver_page_group/${id}/`;
    return this.http.patch<PageGroup>(AppService.getAppUri(URL), data);
  }

  public deletePathSettings(siteId: number, id: number) {
    return this.http.delete(AppService.getAppUri(`/ats/site_setting/${siteId}/path_setting/${id}/`));
  }

  public getLastBuildStatus(siteId: number): Observable<AtsBuildResponse> {
    return this.http.get<AtsBuildResponse>(AppService.getAppUri(`/ats/site_setting/${siteId}/ats_version/last_build_status/`));
  }

  public versionById(siteId: number, versionId: number): Observable<AtsVersion> {
    return this.http.get<AtsVersion>(AppService.getAppUri(`/ats/site_setting/${siteId}/ats_version/${versionId}/`));
  }

  validateDomain(domain: string) {
    const url = AppService.getAppUri('/reference/check_dns');
    return this.http.post<{ valid: boolean }>(url, { domain });
  }

  public clearCacheAtsSite(siteId: number): Observable<HttpResponse<any>> {
    return this.http.get(AppService.getAppUri(`/ats/site_setting/${siteId}/ats_version/clear_cache/`), { observe: 'response' });
  }

  public checkIfChannelIdExists(siteId: number, channel_id: string): Observable<any> {
    const url = AppService.getAppUri(`/ats/site_setting/${siteId}/youtube_channel/`);
    const data = { channel_id };

    return this.http.post(url, data, { observe: 'response' });
  }

  public getPreviewVideoAds(
    siteID: number,
    settingID: number,
    adType: PathSettingType,
    data: Partial<CommonAd>
  ): Observable<{ status: string; url: string }> {
    const URI: string = `/ats/site_setting/${siteID}/${this.getAdTypeURI(adType)}/preview/`;
    return this.http.post<{ status: string; url: string }>(AppService.getAppUri(URI), data);
  }

  public getPrebidBidderSettingData(query: PrebidBidderSettingParams): Observable<ATSBidderResponse> {
    let paramsQuery = new HttpParams();
    paramsQuery = paramsQuery.appendAll({
      ...query,
      bidder_status: JSON.stringify(query?.bidder_status),
    });
    const url = AppService.getAppUri(`/ats/prebid_bidder/`);

    return this.http.get<ATSBidderResponse>(url, { params: paramsQuery });
  }

  public getUserIdSolution(siteId: number): Observable<UserIdModuleConfigResponse> {
    const URI: string = `/ats/user_id_solution/`;
    return this.http.get<UserIdModuleConfigResponse>(AppService.getAppUri(URI), { params: { site_id: siteId } });
  }

  prevalidateAdagioSiteNameDomain(params: GqlParams<typeof PREVALIDATE_ADAGIO_SITE_NAME_DOMAIN_QUERY>) {
    return this.apollo.query({
      query: PREVALIDATE_ADAGIO_SITE_NAME_DOMAIN_QUERY,
      variables: params,
      fetchPolicy: 'network-only',
    });
  }

  bulkUpdateChangePathRegex(siteId: number, payload: BulkUpdateChangePathRegex) {
    return this.http.post(AppService.getAppUri(`/ats/site_setting/${siteId}/bulk_update_paths/`), payload);
  }

  public getVideoSettings(params: GqlParams<typeof VIDEO_SETTING_LIST_QUERY>) {
    return this.apollo
      .use(this.gqlV2)
      .query({
        query: VIDEO_SETTING_LIST_QUERY,
        variables: params,
        fetchPolicy: 'network-only',
      })
      .pipe(map((response) => response.data.video_settings));
  }

  public getYTVideoSettingDetail(params: GqlParams<typeof YOUTUBE_VIDEOS_QUERY>) {
    return this.apollo
      .use(this.gqlV2)
      .query({
        query: YOUTUBE_VIDEOS_QUERY,
        variables: params,
        fetchPolicy: 'network-only',
      })
      .pipe(map((response) => response.data.youtube_videos));
  }
}
